import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'

import modules from './routes/modules'
import system from './routes/system'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'início' } },

    ...modules,
    ...system,

    { path: '*', redirect: '404' },
  ],
})

function hasCredentials() {
  const dados = localStorage.getItem('data')
  const accessToken = localStorage.getItem('accessToken')
  const refreshToken = localStorage.getItem('refreshToken')
  return !!dados && !!accessToken && !!refreshToken
}

router.beforeEach((to, from, next) => {
  const isLoggedIn = hasCredentials()

  // NÃO TEM OS TOKENS
  if (!isLoggedIn) {
    // TRATA ERRO DE REDIREÇÃO, NÃO É PRA ACONTECER
    if (to.path == '/404') return next({ name: 'login' })

    // CONTROLE DE REDIREÇÃO
    const páginasLivres = ['/login', '/privacidade']
    if (!páginasLivres.includes(to.path)) {
      // EVITA QUE A REDIREÇÃO COMO DO GOOGLE CAUSE O LAYOUT SLIM
      let queryParams = { redirecionar: to.fullPath }
      if (to.path == '/') queryParams = {}

      return next({ name: 'login', query: queryParams })
    }

    return next()
  }

  // TEM OS TOKENS
  if (!canNavigate(to) && to.path != '/403') return next({ name: '403' })
  if (to.meta.redirectIfLoggedIn) return next({ name: 'início' })
  return next()
})

// SPLASH SCREEN
router.afterEach(() => {
  // REMOVE INITIAL LOADING
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) appLoading.style.display = 'none'
})

export default router
