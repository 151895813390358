export const $themeColors = {}
export const $themeBreakpoints = {}

// CONFIG
export const $themeConfig = {
  app: {
    // ALTERA O NOME NO MENU DE NAVEGAÇÃO
    appName: 'Escritório Virtual',
    // eslint-disable-next-line global-require
    appLogoImage: require('@/assets/images/logo/logo.png'), // Will update logo in navigation menu (Branding)
  },
  layout: {
    isRTL: false,
    skin: 'light',
    routerTransition: 'zoom-fade', // 'zoom-fade', 'slide-fade', 'fade-bottom', 'fade', 'zoom-out', 'none'
    type: 'vertical', // 'vertical', 'horizontal'
    contentWidth: 'full', // 'full', 'boxed'
    menu: {
      hidden: false,
      isCollapsed: false,
    },
    navbar: {
      type: 'sticky', // 'static' , 'sticky' , 'floating', 'hidden'
      backgroundColor: '', // 'primary', 'success', 'danger', 'warning'
    },
    footer: {
      type: 'hidden', // 'static', 'sticky', 'hidden'
    },
    customizer: false,
    enableScrollToTop: false,
  },
}
