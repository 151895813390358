/* eslint-disable no-console */

import { register } from 'register-service-worker'
import router from './router'
import store from '@/store'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}serviceWorker.js`, {
    ready() {
      console.log('A aplicação está sendo servida de um service worker.')
    },
    registered() {
      console.log('O service worker foi registrado com sucesso.')
    },
    cached() {
      console.log('O conteúdo foi armazenado no cache para uso posterior.')
    },
    updatefound() {
      console.log('Novo conteúdo foi encontrado e está sendo baixado.')
    },
    async updated(registration) {
      console.log('Novo conteúdo foi baixado. Por favor atualize.')

      await store.dispatch('data/pwaServiceWorkerUpdate', registration)
      router.push({ name: 'atualizar', query: { url: window.location.pathname } })
    },
    offline() {
      console.log('Nenhuma conexão com a internet detectada. A aplicação está rodando em modo offline.')
    },
    error(error) {
      console.error('Ocorreu um erro durante o registro do service worker:', error)
    }
  })
}
