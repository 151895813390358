export default [
  // INÍCIO
  {
    path: '/',
    name: 'início',
    component: () => import('@/views/modules/início/InícioPrincipal.vue'),
    meta: {
      resource: 'system',
      action: 'read',
    },
  },
  {
    path: '/ajuda',
    name: 'ajuda',
    component: () => import('@/views/modules/ajuda/KnowledgeBase.vue'),
    meta: {
      resource: 'system',
      action: 'read',
    },
  },
  {
    path: '/atualizar',
    name: 'atualizar',
    component: () => import('@/views/system/AtualizarPWA.vue'),
    meta: {
      layout: 'full',
      resource: 'system',
      action: 'read',
    },
  },
  {
    path: '/configuracoes',
    name: 'configurações',
    component: () => import('@/views/modules/configurações/ConfiguraçõesPrincipal.vue'),
    meta: {
      pageTitle: 'Configurações da Conta',
      resource: 'system',
      action: 'read',
      breadcrumb: [
        // { text: 'Configurações' },
        { text: 'Configurações da Conta', active: true },
      ],
    },
  },
  {
    path: '/dispositivos',
    name: 'dispositivos',
    component: () => import('@/views/modules/configurações/ConfiguraçõesDispositivos.vue'),
    meta: {
      pageTitle: 'Dispositivos Conectados',
      resource: 'system',
      action: 'read',
      breadcrumb: [
        { text: 'Dispositivos Conectados', active: true },
      ],
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/system/AcessoPrincipal.vue'),
    meta: {
      layout: 'full',
      resource: 'auth',
      action: 'read',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/privacidade',
    name: 'privacidade',
    component: () => import('@/views/system/PolíticaPrivacidade.vue'),
    meta: {
      layout: 'full',
      resource: 'system',
      action: 'read',
    },
  },
  {
    path: '/tray/landing',
    name: 'tray-landing',
    component: () => import('@/views/system/TrayLanding.vue'),
    meta: {
      layout: 'full',
      resource: 'system',
      action: 'read',
    },
  },
  {
    path: '/tray/auth',
    name: 'tray-auth',
    component: () => import('@/views/system/TrayAuth.vue'),
    meta: {
      layout: 'full',
      resource: 'system',
      action: 'read',
    },
  },
  {
    path: '/suporte/alteracoes',
    name: 'suporte-alterações',
    component: () => import('@/views/modules/suporte/SistemaAlterações.vue'),
    meta: {
      pageTitle: 'Alterações',
      resource: 'system',
      action: 'read',
      breadcrumb: [
        { text: 'Suporte' },
        { text: 'Alterações', active: true },
      ],
    },
  },
  {
    path: '/suporte/contatos',
    name: 'suporte-contatos',
    component: () => import('@/views/modules/suporte/SistemaContatoSuporte.vue'),
    meta: {
      pageTitle: 'Contatos',
      resource: 'system',
      action: 'read',
      breadcrumb: [
        { text: 'Suporte' },
        { text: 'Contatos', active: true },
      ],
    },
  },
  {
    path: '/suporte/tutoriais',
    name: 'suporte-tutoriais',
    component: () => import('@/views/modules/suporte/SistemaTutoriais.vue'),
    meta: {
      pageTitle: 'Tutoriais',
      resource: 'system',
      action: 'read',
      breadcrumb: [
        { text: 'Suporte' },
        { text: 'Tutoriais', active: true },
      ],
    },
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/views/system/errors/ErroProibido.vue'),
    meta: {
      layout: 'full',
      resource: 'system',
      action: 'read',
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/system/errors/ErroNãoEncontrado.vue'),
    meta: {
      layout: 'full',
      resource: 'system',
      action: 'read',
    },
  },
]
