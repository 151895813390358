<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
  import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
  import { useWindowSize, useCssVar } from '@vueuse/core'
  import { watch, computed } from '@vue/composition-api'
  import { sync } from '@/libs/app'

  import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
  import useAppConfig from '@core/app-config/useAppConfig'
  import store from '@/store'

  const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
  const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

  export default {
    components: {
      LayoutVertical,
      LayoutFull,

      ScrollToTop,
    },
    setup(props, context) {
      const router = context.root.$router
      const route = context.root.$route

      const { skin, skinClasses } = useAppConfig()
      const { enableScrollToTop } = $themeConfig.layout
      const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']
      const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = colors.length; i < len; i++) {
        $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
      }

      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = breakpoints.length; i < len; i++) {
        const valor = useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2)
        $themeBreakpoints[breakpoints[i]] = Number(valor)
      }

      document.documentElement.setAttribute('dir', 'ltr')

      // TEMA: VERIFICA O TEMA DURANTE A INICIALIZAÇÃO
      if (skin.value === 'dark') document.body.classList.add('dark-layout')
      if (skin.value === 'sistema') {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) document.body.classList.add('dark-layout')
        else document.body.classList.remove('dark-layout')
      }

      // TEMA: SE HOUVER WINDOW.MATCHMEDIA PÕE O LISTENER PRO SISTEMA
      if (window.matchMedia) {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
          if (skin.value === 'sistema') {
            if (event.matches) document.body.classList.add('dark-layout')
            else document.body.classList.remove('dark-layout')
          }
        })
      }

      // SET WINDOW WIDTH IN STORE
      store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
      const { width: windowWidth } = useWindowSize()
      watch(windowWidth, val => {
        store.commit('app/UPDATE_WINDOW_WIDTH', val)
      })

      window.addEventListener('online', sync.rede)
      window.addEventListener('offline', sync.rede)

      // EXIBE O BOTÃO DE INSTALAR PWA
      window.addEventListener('beforeinstallprompt', async event => {
        await store.dispatch('data/pwaBeforeInstallPrompt', event)
      })

      return {
        // layout,
        skinClasses,
        enableScrollToTop,
      }
    },

    // ! WE CAN MOVE THIS COMPUTED: LAYOUT & CONTENTLAYOUTTYPE ONCE WE GET TO USE VUE 3
    // CURRENTLY, ROUTER.CURRENTROUTE IS NOT REACTIVE AND DOESN'T TRIGGER ANY CHANGE
    computed: {
      layout() {
        if (this.$route.meta.layout === 'full') return 'layout-full'
        return `layout-${this.contentLayoutType}`
      },

      contentLayoutType() {
        return this.$store.state.appConfig.layout.type
      },
    },
  }
</script>
