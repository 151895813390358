import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import hotkeys from 'hotkeys-js'
import Dinero from 'dinero.js'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

import './global-components'

import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/vue-select'
import './serviceWorkerRegister'

Dinero.globalLocale = 'pt-BR'
Dinero.globalFormat = '$0,0.00'
Dinero.defaultCurrency = 'BRL'

Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueCompositionAPI)

Vue.prototype.$hotkeys = hotkeys.noConflict(true)
Vue.prototype.$dinero = Dinero

require('@core/scss/core.scss')
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

const vue = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

export default vue
