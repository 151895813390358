export default {
  namespaced: true,
  state: {
    usuário: {
      id: null,
      avatar: null,
      nome: null,
      apelido: null,
      email: null,
      fuso: null,
      formatoData: null,
      últimoAcesso: null,
      funcionário: false,
      vendedor: false,
      supervisor: false,
    },
    empresa: {
      id: null,
      nome: null,
      apelido: null,
      grupo: null,
      módulo: null,
      regimeTributário: null,
      mapasUfPadrão: 'SP',
      mapasLongitude: -48.74,
      mapasLatitude: -22.264,
      mapasZoom: 15,
      vendasSugestãoProdutos: 'N',
      vendasEmpresasExibir: 'N',
      visitasPrecisãoMínima: 500,
      tokensGoogleApi: null,
      clientesExibirTítulosPendentes: 'N',
      vendasImpressãoColunas: 80,
      vendasImpressãoHabilitada: 'N',
      vendasVerificarEstoque: 'N',
      vendasHabilitarNegociações: 'S',
      vendasHabilitarCampanhas: 'N',
      clientesSegmentoObrigatório: 'N',
      clientesRegiãoObrigatória: 'N',
      endereçosBairroObrigatório: 'N',
      endereçosCepObrigatório: 'N',
    },
    filial: {
      id: null,
      nome: null,
      apelido: null,
      cabeçalho: null,
      casasDecimais: null,
      vendasEmitir: null,
    },
    ability: [
      { subject: 'system', action: 'read' },
      { subject: 'auth', action: 'read' },
    ],
    alertas: {
      permissões: [],
    },
    busca: {
      módulos: {
        key: 'nome',
        data: [],
      },
      contatos: {
        key: 'nome',
        data: [],
      },
    },
    pwa: {
      beforeInstall: null,
      update: null,
    },
  },
  getters: {
    tudo(state) {
      return state
    },

    primeiroNome(state) {
      const nome = state.usuário.nome
      const apelido = state.usuário.apelido

      if (apelido) return state.usuário.apelido.split(' ')[0]
      if (nome) return state.usuário.nome.split(' ')[0]
      return state.usuário.nome
    },

    usuário: state => state.usuário,
    empresa: state => state.empresa,
    filial: state => state.filial,
    busca: state => state.busca,
    habilidades: state => state.ability,

    // MUITO USADOS, POR ISSO SÃO DISPONIBILIZADOS INDIVIDUALMENTE
    funcionário: state => state.usuário.funcionário,
    vendedor: state => state.usuário.vendedor,
    supervisor: state => state.usuário.supervisor,

    //  SISTEMA
    pwa: state => state.pwa,
  },
  mutations: {
    tudo(state, data) {
      state.usuário.id = data.usuário.id
      state.usuário.avatar = data.usuário.avatar
      state.usuário.nome = data.usuário.nome
      state.usuário.apelido = data.usuário.apelido
      state.usuário.email = data.usuário.email
      state.usuário.fuso = data.usuário.fuso
      state.usuário.formatoData = data.usuário.formatoData
      state.usuário.últimoAcesso = data.usuário.últimoAcesso
      state.usuário.funcionário = data.usuário.funcionário
      state.usuário.vendedor = data.usuário.vendedor
      state.usuário.supervisor = data.usuário.supervisor

      state.empresa.id = data.empresa.id
      state.empresa.nome = data.empresa.nome
      state.empresa.apelido = data.empresa.apelido
      state.empresa.grupo = data.empresa.grupo
      state.empresa.módulo = data.empresa.módulo
      state.empresa.regimeTributário = data.empresa.regimeTributário
      state.empresa.mapasUfPadrão = data.empresa.mapasUfPadrão
      state.empresa.mapasLongitude = data.empresa.mapasLongitude
      state.empresa.mapasLatitude = data.empresa.mapasLatitude
      state.empresa.mapasZoom = data.empresa.mapasZoom
      state.empresa.vendasSugestãoProdutos = data.empresa.vendasSugestãoProdutos
      state.empresa.vendasEmpresasExibir = data.empresa.vendasEmpresasExibir
      state.empresa.visitasPrecisãoMínima = data.empresa.visitasPrecisãoMínima
      state.empresa.vendasVerificarEstoque = data.empresa.vendasVerificarEstoque
      state.empresa.vendasHabilitarNegociações = data.empresa.vendasHabilitarNegociações
      state.empresa.vendasHabilitarCampanhas = data.empresa.vendasHabilitarCampanhas
      state.empresa.tokensGoogleApi = data.empresa.tokensGoogleApi
      state.empresa.clientesExibirTítulosPendentes = data.empresa.clientesExibirTítulosPendentes
      state.empresa.vendasImpressãoHabilitada = data.empresa.vendasImpressãoHabilitada
      state.empresa.vendasImpressãoColunas = data.empresa.vendasImpressãoColunas
      state.empresa.clientesSegmentoObrigatório = data.empresa.clientesSegmentoObrigatório
      state.empresa.clientesRegiãoObrigatória = data.empresa.clientesRegiãoObrigatória
      state.empresa.endereçosBairroObrigatório = data.empresa.endereçosBairroObrigatório
      state.empresa.endereçosCepObrigatório = data.empresa.endereçosCepObrigatório

      state.filial.id = data.filial.id
      state.filial.nome = data.filial.nome
      state.filial.apelido = data.filial.apelido
      state.filial.cabeçalho = data.filial.cabeçalho
      state.filial.casasDecimais = data.filial.casasDecimais
      state.filial.vendaConsignadaDias = data.filial.vendaConsignadaDias
      state.filial.vendaConsignadaDesconto = data.filial.vendaConsignadaDesconto
      state.filial.vendasEmitir = data.filial.vendasEmitir

      state.busca.módulos.data = data.busca.módulos.data
      state.busca.contatos.data = data.busca.contatos.data
    },

    contatos(state, data) {
      state.busca.contatos.data = data
    },

    // PWA
    pwaBeforeInstallPrompt(state, data) {
      state.pwa.beforeInstall = data
    },
    pwaServiceWorkerUpdate(state, data) {
      state.pwa.update = data
    },
  },
  actions: {
    tudo(context, obj) {
      context.commit('tudo', obj)
    },
    contatos(context, obj) {
      context.commit('contatos', obj)
    },

    // PWA
    pwaBeforeInstallPrompt(context, obj) {
      context.commit('pwaBeforeInstallPrompt', obj)
    },
    pwaServiceWorkerUpdate(context, obj) {
      context.commit('pwaServiceWorkerUpdate', obj)
    },
  },
}
