import Vue from 'vue'
import vSelect from 'vue-select'

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: h => h('lucide-icon', { props: { size: 14, icon: 'X' } }),
  },
  OpenIndicator: {
    render: h => h('lucide-icon', { props: { size: 15, icon: 'ChevronDown' }, class: 'open-indicator' }),
  },
})

Vue.component(vSelect)
