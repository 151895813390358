export default [
  // A
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/modules/admin/AdminListagem.vue'),
    meta: {
      pageTitle: 'Admin',
      resource: 'system',
      action: 'read',
      breadcrumb: [{ text: 'Painel do administrador' }],
    },
  },
  {
    path: '/admin/detalhes',
    name: 'admin-detalhes',
    component: () => import('@/views/modules/admin/AdminDetalhes.vue'),
    meta: {
      pageTitle: 'Admin',
      resource: 'system',
      action: 'read',
      breadcrumb: [
        { text: 'Painel do administrador', to: { name: 'admin' } },
        { text: 'Detalhes', active: true },
      ],
    },
  },
  {
    path: '/admin/icones',
    name: 'admin-ícones',
    component: () => import('@/views/modules/admin/ÍconesLucide.vue'),
    meta: {
      pageTitle: 'Admin',
      resource: 'system',
      action: 'read',
      breadcrumb: [
        { text: 'Painel do administrador', to: { name: 'admin' } },
        { text: 'Ícones', active: true },
      ],
    },
  },
  {
    path: '/admin/novo',
    name: 'admin-novo',
    component: () => import('@/views/modules/admin/AdminNovo.vue'),
    meta: {
      pageTitle: 'Admin',
      resource: 'system',
      action: 'read',
      breadcrumb: [
        { text: 'Painel do administrador', to: { name: 'admin' } },
        { text: 'Novo', active: true },
      ],
    },
  },

  // B
  {
    path: '/bandeiras',
    name: 'bandeiras',
    component: () => import('@/views/modules/bandeiras/BandeirasListagem.vue'),
    meta: {
      pageTitle: 'Bandeiras de Cartões',
      resource: 'bandeiras',
      action: 'read',
      breadcrumb: [{ text: 'Tabelas Gerais' }, { text: 'Bandeiras de Cartões', active: true }],
    },
  },
  {
    path: '/bandeiras/detalhes',
    name: 'bandeiras-detalhes',
    component: () => import('@/views/modules/bandeiras/BandeirasDetalhes.vue'),
    meta: {
      navActiveLink: 'bandeiras',
      pageTitle: 'Bandeiras de Cartões',
      resource: 'bandeiras',
      action: 'read',
      breadcrumb: [
        { text: 'Tabelas Gerais' },
        { text: 'Bandeiras de Cartões', to: { name: 'bandeiras' } },
        { text: 'Detalhes', active: true },
      ],
    },
  },

  // C
  {
    path: '/carteiras',
    name: 'carteiras',
    component: () => import('@/views/modules/carteiras/CarteirasListagem.vue'),
    meta: {
      pageTitle: 'Carteiras',
      resource: 'carteiras',
      action: 'read',
      breadcrumb: [{ text: 'Tabelas Gerais' }, { text: 'Carteiras', active: true }],
    },
  },
  {
    path: '/carteiras/detalhes',
    name: 'carteiras-detalhes',
    component: () => import('@/views/modules/carteiras/CarteirasDetalhes.vue'),
    meta: {
      navActiveLink: 'carteiras',
      pageTitle: 'Carteiras',
      resource: 'carteiras',
      action: 'read',
      breadcrumb: [
        { text: 'Tabelas Gerais' },
        { text: 'Carteiras', to: { name: 'carteiras' } },
        { text: 'Detalhes', active: true },
      ],
    },
  },
  {
    path: '/clientes',
    name: 'clientes',
    component: () => import('@/views/modules/clientes/ClientesListagem.vue'),
    meta: {
      pageTitle: 'Clientes',
      resource: 'clientes',
      action: 'read',
      breadcrumb: [{ text: 'Clientes', active: true }],
    },
  },
  {
    path: '/clientes/detalhes',
    name: 'clientes-detalhes',
    component: () => import('@/views/modules/clientes/ClientesDetalhes.vue'),
    meta: {
      navActiveLink: 'clientes',
      pageTitle: 'Clientes',
      resource: 'clientes',
      action: 'read',
      breadcrumb: [
        { text: 'Clientes', to: { name: 'clientes' } },
        { text: 'Detalhes', active: true },
      ],
    },
  },
  {
    path: '/clientes/dashboard',
    name: 'clientes-dashboard',
    component: () => import('@/views/modules/clientes/ClientesDashboard.vue'),
    meta: {
      navActiveLink: 'clientes-dashboard',
      pageTitle: 'Clientes',
      resource: 'clientes',
      action: 'dashboard',
      breadcrumb: [
        { text: 'Clientes', to: { name: 'clientes' } },
        { text: 'Dashboard', active: true },
      ],
    },
  },
  {
    path: '/clientes-classificacoes',
    name: 'clientes-classificações',
    component: () => import('@/views/modules/clientes-classificações/ClientesClassificaçõesListagem.vue'),
    meta: {
      pageTitle: 'Classificações de Clientes',
      resource: 'clientes-classificações',
      action: 'read',
      breadcrumb: [{ text: 'Tabelas Gerais' }, { text: 'Classificações de Clientes', active: true }],
    },
  },
  {
    path: '/clientes-classificacoes/detalhes',
    name: 'clientes-classificações-detalhes',
    component: () => import('@/views/modules/clientes-classificações/ClientesClassificaçõesDetalhes.vue'),
    meta: {
      navActiveLink: 'clientes-classificações',
      pageTitle: 'Classificações de Clientes',
      resource: 'clientes-classificações',
      action: 'read',
      breadcrumb: [
        { text: 'Tabelas Gerais' },
        { text: 'Classificações de Clientes', to: { name: 'clientes-classificações' } },
        { text: 'Detalhes', active: true },
      ],
    },
  },
  {
    path: '/contas',
    name: 'contas',
    component: () => import('@/views/modules/contas/ContasListagem.vue'),
    meta: {
      pageTitle: 'Contas',
      resource: 'contas',
      action: 'read',
      breadcrumb: [{ text: 'Tabelas Gerais' }, { text: 'Contas', active: true }],
    },
  },
  {
    path: '/contas/detalhes',
    name: 'contas-detalhes',
    component: () => import('@/views/modules/contas/ContasDetalhes.vue'),
    meta: {
      navActiveLink: 'contas',
      pageTitle: 'Contas',
      resource: 'contas',
      action: 'read',
      breadcrumb: [{ text: 'Tabelas Gerais' }, { text: 'Contas', to: { name: 'contas' } }, { text: 'Detalhes', active: true }],
    },
  },
  {
    path: '/contatos',
    name: 'contatos',
    component: () => import('@/views/modules/contatos/ContatosListagem.vue'),
    meta: {
      pageTitle: 'Contatos',
      resource: 'contatos',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Contatos', active: true }],
    },
  },
  {
    path: '/contatos/detalhes',
    name: 'contatos-detalhes',
    component: () => import('@/views/modules/contatos/ContatosDetalhes.vue'),
    meta: {
      navActiveLink: 'contatos',
      pageTitle: 'Contatos',
      resource: 'contatos',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Contatos', to: { name: 'contatos' } }, { text: 'Detalhes', active: true }],
    },
  },
  {
    path: '/cores',
    name: 'cores',
    component: () => import('@/views/modules/cores/CoresListagem.vue'),
    meta: {
      pageTitle: 'Cores',
      resource: 'cores',
      action: 'read',
      breadcrumb: [{ text: 'Tabelas Gerais' }, { text: 'Cores', active: true }],
    },
  },
  {
    path: '/cores/detalhes',
    name: 'cores-detalhes',
    component: () => import('@/views/modules/cores/CoresDetalhes.vue'),
    meta: {
      navActiveLink: 'cores',
      pageTitle: 'Cores',
      resource: 'cores',
      action: 'read',
      breadcrumb: [{ text: 'Tabelas Gerais' }, { text: 'Cores', to: { name: 'cores' } }, { text: 'Detalhes', active: true }],
    },
  },

  // E
  {
    path: '/emitentes',
    name: 'emitentes',
    component: () => import('@/views/modules/emitentes/EmitentesListagem.vue'),
    meta: {
      pageTitle: 'Emitentes de Cheques',
      resource: 'emitentes',
      action: 'read',
      breadcrumb: [{ text: 'Financeiro' }, { text: 'Emitentes de Cheques', active: true }],
    },
  },
  {
    path: '/emitentes/detalhes',
    name: 'emitentes-detalhes',
    component: () => import('@/views/modules/emitentes/EmitentesDetalhes.vue'),
    meta: {
      navActiveLink: 'emitentes',
      pageTitle: 'Emitentes',
      resource: 'emitentes',
      action: 'read',
      breadcrumb: [
        { text: 'Financeiro' },
        { text: 'Emitentes de Cheques', to: { name: 'emitentes' } },
        { text: 'Detalhes', active: true },
      ],
    },
  },
  {
    path: '/empresas',
    name: 'empresas',
    component: () => import('@/views/modules/empresas/EmpresasListagem.vue'),
    meta: {
      pageTitle: 'Empresas',
      resource: 'empresas',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Empresas', active: true }],
    },
  },
  {
    path: '/empresas/detalhes',
    name: 'empresas-detalhes',
    component: () => import('@/views/modules/empresas/EmpresasDetalhes.vue'),
    meta: {
      navActiveLink: 'empresas',
      pageTitle: 'Empresas',
      resource: 'empresas',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Empresas', to: { name: 'empresas' } }, { text: 'Detalhes', active: true }],
    },
  },
  {
    path: '/estoques',
    name: 'estoques',
    component: () => import('@/views/modules/estoques/EstoquesPosições.vue'),
    meta: {
      pageTitle: 'Posição de Estoque',
      resource: 'estoques',
      action: 'read',
      breadcrumb: [{ text: 'Estoques' }, { text: 'Posição', active: true }],
    },
  },
  // {
  //   path: '/estoques/entrada',
  //   name: 'estoques-entrada',
  //   component: () => import('@/views/modules/estoques/EstoquesEntradaListagem.vue'),
  //   meta: {
  //     navActiveLink: 'estoques-entrada',
  //     pageTitle: 'Pedido de Entrada',
  //     resource: 'estoques',
  //     action: 'read',
  //     breadcrumb: [
  //       { text: 'Estoques', to: { name: 'estoques' } },
  //       { text: 'Entrada', active: true },
  //     ],
  //   },
  // },
  {
    path: '/estoques/entrada/detalhes',
    name: 'estoques-entrada-detalhes',
    component: () => import('@/views/modules/estoques/EstoquesEntradaDetalhes.vue'),
    meta: {
      navActiveLink: 'estoques-entrada',
      pageTitle: 'Pedido de Entrada',
      resource: 'estoques',
      action: 'read',
      breadcrumb: [
        { text: 'Estoques', to: { name: 'estoques' } },
        { text: 'Entrada', to: { name: 'estoques-entrada' } },
        { text: 'Detalhes', active: true },
      ],
    },
  },

  // F
  {
    path: '/filiais',
    name: 'filiais',
    component: () => import('@/views/modules/filiais/FiliaisListagem.vue'),
    meta: {
      pageTitle: 'Centros de Resultados',
      resource: 'filiais',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Centros de Resultados', active: true }],
    },
  },
  {
    path: '/filiais/detalhes',
    name: 'filiais-detalhes',
    component: () => import('@/views/modules/filiais/FiliaisDetalhes.vue'),
    meta: {
      navActiveLink: 'filiais',
      pageTitle: 'Centros de Resultados',
      resource: 'filiais',
      action: 'read',
      breadcrumb: [
        { text: 'Cadastros' },
        { text: 'Centros de Resultados', to: { name: 'filiais' } },
        { text: 'Detalhes', active: true },
      ],
    },
  },
  {
    path: '/filiais-classificacoes',
    name: 'filiais-classificações',
    component: () => import('@/views/modules/filiais-classificações/FiliaisClassificaçõesListagem.vue'),
    meta: {
      pageTitle: 'Classificações de Centros de Resultados',
      resource: 'filiais-classificações',
      action: 'read',
      breadcrumb: [{ text: 'Tabelas Gerais' }, { text: 'Classificações de Centros de Resultados', active: true }],
    },
  },
  {
    path: '/filiais-classificacoes/detalhes',
    name: 'filiais-classificações-detalhes',
    component: () => import('@/views/modules/filiais-classificações/FiliaisClassificaçõesDetalhes.vue'),
    meta: {
      navActiveLink: 'filiais-classificações',
      pageTitle: 'Classificações de Centros de Resultados',
      resource: 'filiais-classificações',
      action: 'read',
      breadcrumb: [
        { text: 'Tabelas Gerais' },
        { text: 'Classificações de Centros de Resultados', to: { name: 'filiais-classificações' } },
        { text: 'Detalhes', active: true },
      ],
    },
  },
  {
    path: '/fornecedores',
    name: 'fornecedores',
    component: () => import('@/views/modules/fornecedores/FornecedoresListagem.vue'),
    meta: {
      pageTitle: 'Fornecedores',
      resource: 'fornecedores',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Fornecedores', active: true }],
    },
  },
  {
    path: '/fornecedores/detalhes',
    name: 'fornecedores-detalhes',
    component: () => import('@/views/modules/fornecedores/FornecedoresDetalhes.vue'),
    meta: {
      navActiveLink: 'fornecedores',
      pageTitle: 'Fornecedores',
      resource: 'fornecedores',
      action: 'read',
      breadcrumb: [
        { text: 'Cadastros' },
        { text: 'Fornecedores', to: { name: 'fornecedores' } },
        { text: 'Detalhes', active: true },
      ],
    },
  },
  {
    path: '/fornecedores-classificacoes',
    name: 'fornecedores-classificações',
    component: () => import('@/views/modules/fornecedores-classificações/FornecedoresClassificaçõesListagem.vue'),
    meta: {
      pageTitle: 'Classificações de Fornecedores',
      resource: 'fornecedores-classificações',
      action: 'read',
      breadcrumb: [{ text: 'Tabelas Gerais' }, { text: 'Classificações de Fornecedores', active: true }],
    },
  },
  {
    path: '/fornecedores-classificacoes/detalhes',
    name: 'fornecedores-classificações-detalhes',
    component: () => import('@/views/modules/fornecedores-classificações/FornecedoresClassificaçõesDetalhes.vue'),
    meta: {
      navActiveLink: 'fornecedores-classificações',
      pageTitle: 'Classificações de Fornecedores',
      resource: 'fornecedores-classificações',
      action: 'read',
      breadcrumb: [
        { text: 'Tabelas Gerais' },
        { text: 'Classificações de Fornecedores', to: { name: 'fornecedores-classificações' } },
        { text: 'Detalhes', active: true },
      ],
    },
  },

  // G
  {
    path: '/gerenciais/dashboard',
    name: 'gerenciais-dashboard',
    component: () => import('@/views/modules/gerenciais/GerenciaisDashboard.vue'),
    meta: {
      // navActiveLink: 'gerenciais-dashboard',
      pageTitle: 'Gerenciais - Dashboard',
      resource: 'gerenciais',
      action: 'dashboard',
      breadcrumb: [
        { text: 'Gerenciais', to: { name: 'gerenciais' } },
        { text: 'Dashboard', active: true },
      ],
    },
  },
  {
    path: '/gerenciais/cesta-compras',
    name: 'gerenciais-cesta-compras',
    component: () => import('@/views/modules/gerenciais/GerenciaisCestaCompras.vue'),
    meta: {
      // navActiveLink: 'gerenciais-dashboard',
      pageTitle: 'Gerenciais - Análise da Cesta de Compras',
      resource: 'gerenciais',
      action: 'read',
      breadcrumb: [
        { text: 'Gerenciais', to: { name: 'gerenciais' } },
        { text: 'Análise da Cesta de Compras', active: true },
      ],
    },
  },

  // H
  {
    path: '/habilidades',
    name: 'permissões',
    component: () => import('@/views/modules/habilidades/HabilidadesDetalhes.vue'),
    meta: {
      pageTitle: 'Permissões',
      resource: 'permissões',
      action: 'read',
      breadcrumb: [{ text: 'Configurações' }, { text: 'Permissões', active: true }],
    },
  },

  // L
  {
    path: '/lancamentos',
    name: 'lançamentos',
    component: () => import('@/views/modules/lançamentos/LançamentosDetalhes.vue'),
    meta: {
      pageTitle: 'Bancos',
      resource: 'lançamentos',
      action: 'read',
      breadcrumb: [{ text: 'Financeiro' }, { text: 'Bancos', active: true }],
    },
  },
  {
    path: '/leads',
    name: 'leads',
    component: () => import('@/views/modules/leads/LeadsListagem.vue'),
    meta: {
      pageTitle: 'Leads',
      resource: 'leads',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Leads', active: true }],
    },
  },
  {
    path: '/leads/detalhes',
    name: 'leads-detalhes',
    component: () => import('@/views/modules/leads/LeadsDetalhes.vue'),
    meta: {
      navActiveLink: 'leads',
      pageTitle: 'Leads',
      resource: 'leads',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Leads', to: { name: 'leads' } }, { text: 'Detalhes', active: true }],
    },
  },
  {
    path: '/leads/dashboard',
    name: 'leads-dashboard',
    component: () => import('@/views/modules/leads/LeadsDashboard.vue'),
    meta: {
      navActiveLink: 'leads-dashboard',
      pageTitle: 'Leads',
      resource: 'leads',
      action: 'dashboard',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Leads', to: { name: 'leads' } }, { text: 'Dashboard', active: true }],
    },
  },
  {
    path: '/loja-virtual/alertas',
    name: 'loja-virtual-alertas',
    component: () => import('@/views/modules/loja-virtual/LojaVirtualAlertas.vue'),
    meta: {
      pageTitle: 'Alertas pela Loja Virtual',
      resource: 'loja-virtual',
      action: 'read',
      breadcrumb: [{ text: 'Loja Virtual' }, { text: 'Alertas', active: true }],
    },
  },
  {
    path: '/loja-virtual/contatos',
    name: 'loja-virtual-contatos',
    component: () => import('@/views/modules/loja-virtual/LojaVirtualContatos.vue'),
    meta: {
      pageTitle: 'Contatos pela Loja Virtual',
      resource: 'loja-virtual',
      action: 'read',
      breadcrumb: [{ text: 'Loja Virtual' }, { text: 'Contatos', active: true }],
    },
  },
  {
    path: '/loja-virtual/status',
    name: 'loja-virtual-status',
    component: () => import('@/views/modules/loja-virtual/LojaVirtualStatus.vue'),
    meta: {
      pageTitle: 'Status da Loja Virtual',
      resource: 'loja-virtual',
      action: 'read',
      breadcrumb: [{ text: 'Loja Virtual' }, { text: 'Status', active: true }],
    },
  },

  // M
  {
    path: '/mercadolivre',
    name: 'mercadolivre',
    component: () => import('@/views/modules/integrações/MercadoLivre.vue'),
    meta: {
      pageTitle: 'Mercado Livre',
      resource: 'mercadolivre',
      action: 'read',
      breadcrumb: [{ text: 'Integrações' }, { text: 'Mercado Livre', active: true }],
    },
  },
  {
    path: '/moedas',
    name: 'moedas',
    component: () => import('@/views/modules/moedas/MoedasListagem.vue'),
    meta: {
      pageTitle: 'Moedas',
      resource: 'moedas',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Moedas', active: true }],
    },
  },
  {
    path: '/moedas/detalhes',
    name: 'moedas-detalhes',
    component: () => import('@/views/modules/moedas/MoedasDetalhes.vue'),
    meta: {
      navActiveLink: 'moedas',
      pageTitle: 'Moedas',
      resource: 'moedas',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Moedas', to: { name: 'moedas' } }, { text: 'Detalhes', active: true }],
    },
  },
  {
    path: '/motivos',
    name: 'motivos',
    component: () => import('@/views/modules/motivos/MotivosListagem.vue'),
    meta: {
      pageTitle: 'Motivos',
      resource: 'motivos',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Motivos', active: true }],
    },
  },
  {
    path: '/motivos/detalhes',
    name: 'motivos-detalhes',
    component: () => import('@/views/modules/motivos/MotivosDetalhes.vue'),
    meta: {
      navActiveLink: 'motivos',
      pageTitle: 'Motivos',
      resource: 'motivos',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Motivos', to: { name: 'motivos' } }, { text: 'Detalhes', active: true }],
    },
  },

  // N
  {
    path: '/naturezas',
    name: 'naturezas',
    component: () => import('@/views/modules/naturezas/NaturezasListagem.vue'),
    meta: {
      pageTitle: 'Naturezas de Operação Fiscal',
      resource: 'naturezas',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Naturezas de Operação Fiscal', active: true }],
    },
  },
  {
    path: '/naturezas/detalhes',
    name: 'naturezas-detalhes',
    component: () => import('@/views/modules/naturezas/NaturezasDetalhes.vue'),
    meta: {
      navActiveLink: 'naturezas',
      pageTitle: 'Naturezas de Operação Fiscal',
      resource: 'naturezas',
      action: 'read',
      breadcrumb: [
        { text: 'Cadastros' },
        { text: 'Naturezas de Operação Fiscal', to: { name: 'naturezas' } },
        { text: 'Detalhes', active: true },
      ],
    },
  },
  {
    path: '/ncm',
    name: 'ncm',
    component: () => import('@/views/modules/ncm/NcmListagem.vue'),
    meta: {
      pageTitle: 'NCM',
      resource: 'ncm',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'NCM', active: true }],
    },
  },
  {
    path: '/ncm/detalhes',
    name: 'ncm-detalhes',
    component: () => import('@/views/modules/ncm/NcmDetalhes.vue'),
    meta: {
      navActiveLink: 'ncm',
      pageTitle: 'NCM',
      resource: 'ncm',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'NCM', to: { name: 'ncm' } }, { text: 'Detalhes', active: true }],
    },
  },

  // P
  {
    path: '/pagamentos-condicoes',
    name: 'pagamentos-condições',
    component: () => import('@/views/modules/pagamentos-condições/PagamentosCondiçõesListagem.vue'),
    meta: {
      pageTitle: 'Condições de Pagamento',
      resource: 'pagamentos-condições',
      action: 'read',
      breadcrumb: [{ text: 'Financeiro' }, { text: 'Condições de Pagamento', active: true }],
    },
  },
  {
    path: '/pagamentos-condicoes/detalhes',
    name: 'pagamentos-condições-detalhes',
    component: () => import('@/views/modules/pagamentos-condições/PagamentosCondiçõesDetalhes.vue'),
    meta: {
      navActiveLink: 'pagamentos-condições',
      pageTitle: 'Condições de Pagamento',
      resource: 'pagamentos-condições',
      action: 'read',
      breadcrumb: [
        { text: 'Financeiro' },
        { text: 'Condições de Pagamento', to: { name: 'pagamentos-condições' } },
        { text: 'Detalhes', active: true },
      ],
    },
  },
  {
    path: '/pagamentos-tipos',
    name: 'pagamentos-tipos',
    component: () => import('@/views/modules/pagamentos-tipos/PagamentosTiposListagem.vue'),
    meta: {
      pageTitle: 'Formas de Pagamento',
      resource: 'pagamentos-tipos',
      action: 'read',
      breadcrumb: [{ text: 'Financeiro' }, { text: 'Formas de Pagamento', active: true }],
    },
  },
  {
    path: '/pagamentos-tipos/detalhes',
    name: 'pagamentos-tipos-detalhes',
    component: () => import('@/views/modules/pagamentos-tipos/PagamentosTiposDetalhes.vue'),
    meta: {
      navActiveLink: 'pagamentos-tipos',
      pageTitle: 'Formas de Pagamento',
      resource: 'pagamentos-tipos',
      action: 'read',
      breadcrumb: [
        { text: 'Financeiro' },
        { text: 'Formas de Pagamento', to: { name: 'pagamentos-tipos' } },
        { text: 'Detalhes', active: true },
      ],
    },
  },
  {
    path: '/perfis',
    name: 'perfis',
    component: () => import('@/views/modules/perfis/PerfisListagem.vue'),
    meta: {
      pageTitle: 'Perfis',
      resource: 'perfis',
      action: 'read',
      breadcrumb: [{ text: 'Perfis', active: true }],
    },
  },
  {
    path: '/perfis/detalhes',
    name: 'perfis-detalhes',
    component: () => import('@/views/modules/perfis/PerfisDetalhes.vue'),
    meta: {
      navActiveLink: 'perfis',
      pageTitle: 'Perfis',
      resource: 'perfis',
      action: 'read',
      breadcrumb: [
        { text: 'Perfis', to: { name: 'perfis' } },
        { text: 'Detalhes', active: true },
      ],
    },
  },
  {
    path: '/portadores',
    name: 'portadores',
    component: () => import('@/views/modules/portadores/PortadoresListagem.vue'),
    meta: {
      pageTitle: 'Portadores',
      resource: 'portadores',
      action: 'read',
      breadcrumb: [{ text: 'Portadores', active: true }],
    },
  },
  {
    path: '/portadores/detalhes',
    name: 'portadores-detalhes',
    component: () => import('@/views/modules/portadores/PortadoresDetalhes.vue'),
    meta: {
      navActiveLink: 'portadores',
      pageTitle: 'Portadores',
      resource: 'portadores',
      action: 'read',
      breadcrumb: [{ text: 'Portadores' }, { text: 'Detalhes', active: true }],
    },
  },
  {
    path: '/produtos',
    name: 'produtos',
    component: () => import('@/views/modules/produtos/ProdutosListagem.vue'),
    meta: {
      pageTitle: 'Produtos',
      resource: 'produtos',
      action: 'read',
      breadcrumb: [{ text: 'Produtos', active: true }],
    },
  },
  {
    path: '/produtos/detalhes',
    name: 'produtos-detalhes',
    component: () => import('@/views/modules/produtos/ProdutosDetalhes.vue'),
    meta: {
      navActiveLink: 'produtos',
      pageTitle: 'Produtos',
      resource: 'produtos',
      action: 'read',
      breadcrumb: [
        { text: 'Produtos', to: { name: 'produtos' } },
        { text: 'Detalhes', active: true },
      ],
    },
  },
  {
    path: '/produtos-aplicacoes',
    name: 'produtos-aplicações',
    component: () => import('@/views/modules/produtos-aplicações/ProdutosAplicaçõesListagem.vue'),
    meta: {
      pageTitle: 'Aplicações de Produtos',
      resource: 'produtos-aplicações',
      action: 'read',
      breadcrumb: [
        { text: 'Produtos', to: { name: 'produtos' } },
        { text: 'Aplicações de Produtos', active: true },
      ],
    },
  },
  {
    path: '/produtos-aplicacoes/detalhes',
    name: 'produtos-aplicações-detalhes',
    component: () => import('@/views/modules/produtos-aplicações/ProdutosAplicaçõesDetalhes.vue'),
    meta: {
      navActiveLink: 'produtos-aplicações',
      pageTitle: 'Aplicações de Produtos',
      resource: 'produtos-aplicações',
      action: 'read',
      breadcrumb: [
        { text: 'Produtos', to: { name: 'produtos' } },
        { text: 'Aplicações de Produtos', to: { name: 'produtos-aplicações' } },
        { text: 'Detalhes', active: true },
      ],
    },
  },
  {
    path: '/produtos-ecf',
    name: 'produtos-ecf',
    component: () => import('@/views/modules/produtos-ecf/ECFListagem.vue'),
    meta: {
      pageTitle: 'ECF',
      resource: 'produtos-ecf',
      action: 'read',
      breadcrumb: [
        { text: 'Produtos', to: { name: 'produtos' } },
        { text: 'ECF', active: true },
      ],
    },
  },
  {
    path: '/produtos-ecf/detalhes',
    name: 'produtos-ecf-detalhes',
    component: () => import('@/views/modules/produtos-ecf/ECFDetalhes.vue'),
    meta: {
      navActiveLink: 'produtos-ecf',
      pageTitle: 'ECF',
      resource: 'produtos-ecf',
      action: 'read',
      breadcrumb: [
        { text: 'Produtos', to: { name: 'produtos' } },
        { text: 'ECF', to: { name: 'produtos-ecf' } },
        { text: 'Detalhes', active: true },
      ],
    },
  },
  {
    path: '/produtos-grupos',
    name: 'produtos-grupos',
    component: () => import('@/views/modules/produtos-grupos/ProdutosGruposListagem.vue'),
    meta: {
      pageTitle: 'Grupos de Produtos',
      resource: 'produtos-grupos',
      action: 'read',
      breadcrumb: [
        { text: 'Produtos', to: { name: 'produtos' } },
        { text: 'Grupos de Produtos', active: true },
      ],
    },
  },
  {
    path: '/produtos-grupos/detalhes',
    name: 'produtos-grupos-detalhes',
    component: () => import('@/views/modules/produtos-grupos/ProdutosGruposDetalhes.vue'),
    meta: {
      navActiveLink: 'produtos-grupos',
      pageTitle: 'Grupos de Produtos',
      resource: 'produtos-grupos',
      action: 'read',
      breadcrumb: [
        { text: 'Produtos', to: { name: 'produtos' } },
        { text: 'Grupos de Produtos', to: { name: 'produtos-grupos' } },
        { text: 'Detalhes', active: true },
      ],
    },
  },

  // R
  {
    path: '/regioes',
    name: 'regiões',
    component: () => import('@/views/modules/regiões/RegiõesListagem.vue'),
    meta: {
      pageTitle: 'Regiões',
      resource: 'regiões',
      action: 'read',
      breadcrumb: [{ text: 'Tabelas Gerais' }, { text: 'Regiões', active: true }],
    },
  },
  {
    path: '/regioes/detalhes',
    name: 'regiões-detalhes',
    component: () => import('@/views/modules/regiões/RegiõesDetalhes.vue'),
    meta: {
      navActiveLink: 'regiões',
      pageTitle: 'Regiões',
      resource: 'regiões',
      action: 'read',
      breadcrumb: [{ text: 'Tabelas Gerais' }, { text: 'Regiões', to: { name: 'regiões' } }, { text: 'Detalhes', active: true }],
    },
  },
  {
    path: '/rotas',
    name: 'rotas',
    component: () => import('@/views/modules/rotas/RotasListagem.vue'),
    meta: {
      pageTitle: 'Rotas',
      resource: 'rotas',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Rotas', active: true }],
    },
  },
  {
    path: '/rotas/detalhes',
    name: 'rotas-detalhes',
    component: () => import('@/views/modules/rotas/RotasDetalhes.vue'),
    meta: {
      navActiveLink: 'rotas',
      pageTitle: 'Rotas',
      resource: 'rotas',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Rotas', to: { name: 'rotas' } }, { text: 'Detalhes', active: true }],
    },
  },

  // S
  {
    path: '/segmentos',
    name: 'segmentos',
    component: () => import('@/views/modules/segmentos/SegmentosListagem.vue'),
    meta: {
      pageTitle: 'Segmentos',
      resource: 'segmentos',
      action: 'read',
      breadcrumb: [{ text: 'Tabelas Gerais' }, { text: 'Segmentos', active: true }],
    },
  },
  {
    path: '/segmentos/detalhes',
    name: 'segmentos-detalhes',
    component: () => import('@/views/modules/segmentos/SegmentosDetalhes.vue'),
    meta: {
      navActiveLink: 'segmentos',
      pageTitle: 'Segmentos',
      resource: 'segmentos',
      action: 'read',
      breadcrumb: [
        { text: 'Tabelas Gerais' },
        { text: 'Segmentos', to: { name: 'segmentos' } },
        { text: 'Detalhes', active: true },
      ],
    },
  },
  {
    path: '/situacoes',
    name: 'situações',
    component: () => import('@/views/modules/situações/SituaçõesListagem.vue'),
    meta: {
      pageTitle: 'Situações',
      resource: 'situações',
      action: 'read',
      breadcrumb: [{ text: 'Tabelas Gerais' }, { text: 'Situações', active: true }],
    },
  },
  {
    path: '/situacoes/detalhes',
    name: 'situações-detalhes',
    component: () => import('@/views/modules/situações/SituaçõesDetalhes.vue'),
    meta: {
      navActiveLink: 'situações',
      pageTitle: 'Situações',
      resource: 'situações',
      action: 'read',
      breadcrumb: [
        { text: 'Tabelas Gerais' },
        { text: 'Situações', to: { name: 'situações' } },
        { text: 'Detalhes', active: true },
      ],
    },
  },
  {
    path: '/sorteios',
    name: 'sorteios',
    component: () => import('@/views/modules/sorteios/SorteiosListagem.vue'),
    meta: {
      pageTitle: 'Sorteios',
      resource: 'sorteios',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Sorteios', active: true }],
    },
  },
  {
    path: '/sorteios/detalhes',
    name: 'sorteios-detalhes',
    component: () => import('@/views/modules/sorteios/SorteiosDetalhes.vue'),
    meta: {
      navActiveLink: 'sorteios',
      pageTitle: 'Sorteios',
      resource: 'sorteios',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Sorteios', to: { name: 'sorteios' } }, { text: 'Detalhes', active: true }],
    },
  },

  // T
  {
    path: '/tamanhos',
    name: 'tamanhos',
    component: () => import('@/views/modules/tamanhos/TamanhosListagem.vue'),
    meta: {
      pageTitle: 'Tamanhos',
      resource: 'tamanhos',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Tamanhos', active: true }],
    },
  },
  {
    path: '/tamanhos/detalhes',
    name: 'tamanhos-detalhes',
    component: () => import('@/views/modules/tamanhos/TamanhosDetalhes.vue'),
    meta: {
      navActiveLink: 'tamanhos',
      pageTitle: 'Tamanhos',
      resource: 'tamanhos',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Tamanhos', to: { name: 'tamanhos' } }, { text: 'Detalhes', active: true }],
    },
  },
  {
    path: '/textos-fiscais',
    name: 'textos-fiscais',
    component: () => import('@/views/modules/textos-fiscais/TextosFiscaisListagem.vue'),
    meta: {
      pageTitle: 'Textos de Classificação',
      resource: 'textos-fiscais',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Textos de Classificação', active: true }],
    },
  },
  {
    path: '/textos-fiscais/detalhes',
    name: 'textos-fiscais-detalhes',
    component: () => import('@/views/modules/textos-fiscais/TextosFiscaisDetalhes.vue'),
    meta: {
      navActiveLink: 'textos-fiscais',
      pageTitle: 'Textos de Classificação',
      resource: 'textos-fiscais',
      action: 'read',
      breadcrumb: [
        { text: 'Cadastros' },
        { text: 'Textos de Classificação', to: { name: 'textos-fiscais' } },
        { text: 'Detalhes', active: true },
      ],
    },
  },
  {
    path: '/tipos-despesas',
    name: 'tipos-despesas',
    component: () => import('@/views/modules/tipos-despesas/TiposDespesasListagem.vue'),
    meta: {
      pageTitle: 'Tipos de Despesas',
      resource: 'tipos-despesas',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Tipos de Despesas', active: true }],
    },
  },
  {
    path: '/tipos-despesas/detalhes',
    name: 'tipos-despesas-detalhes',
    component: () => import('@/views/modules/tipos-despesas/TiposDespesasDetalhes.vue'),
    meta: {
      navActiveLink: 'tipos-despesas',
      pageTitle: 'Tipos de Despesas',
      resource: 'tipos-despesas',
      action: 'read',
      breadcrumb: [
        { text: 'Cadastros' },
        { text: 'Tipos de Despesas', to: { name: 'tipos-despesas' } },
        { text: 'Detalhes', active: true },
      ],
    },
  },
  {
    path: '/tipos-ocorrencias',
    name: 'tipos-ocorrências',
    component: () => import('@/views/modules/tipos-ocorrências/TiposOcorrênciasListagem.vue'),
    meta: {
      pageTitle: 'Tipos de Ocorrências',
      resource: 'tipos-ocorrências',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Tipos de Ocorrências', active: true }],
    },
  },
  {
    path: '/tipos-ocorrencias/detalhes',
    name: 'tipos-ocorrências-detalhes',
    component: () => import('@/views/modules/tipos-ocorrências/TiposOcorrênciasDetalhes.vue'),
    meta: {
      navActiveLink: 'tipos-ocorrências',
      pageTitle: 'Tipos de Ocorrências',
      resource: 'tipos-ocorrências',
      action: 'read',
      breadcrumb: [
        { text: 'Cadastros' },
        { text: 'Tipos de Ocorrências', to: { name: 'tipos-ocorrências' } },
        { text: 'Detalhes', active: true },
      ],
    },
  },
  {
    path: '/tipos-receitas',
    name: 'tipos-receitas',
    component: () => import('@/views/modules/tipos-receitas/TiposReceitasListagem.vue'),
    meta: {
      pageTitle: 'Tipos de Receitas',
      resource: 'tipos-receitas',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Tipos de Receitas', active: true }],
    },
  },
  {
    path: '/tipos-receitas/detalhes',
    name: 'tipos-receitas-detalhes',
    component: () => import('@/views/modules/tipos-receitas/TiposReceitasDetalhes.vue'),
    meta: {
      navActiveLink: 'tipos-receitas',
      pageTitle: 'Tipos de Receitas',
      resource: 'tipos-receitas',
      action: 'read',
      breadcrumb: [
        { text: 'Cadastros' },
        { text: 'Tipos de Receitas', to: { name: 'tipos-receitas' } },
        { text: 'Detalhes', active: true },
      ],
    },
  },
  {
    path: '/vendas-tipos',
    name: 'vendas-tipos',
    component: () => import('@/views/modules/vendas-tipos/VendasTiposListagem.vue'),
    meta: {
      pageTitle: 'Tipos de Vendas',
      resource: 'vendas-tipos',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Tipos de Vendas', active: true }],
    },
  },
  {
    path: '/vendas-tipos/detalhes',
    name: 'vendas-tipos-detalhes',
    component: () => import('@/views/modules/vendas-tipos/VendasTiposDetalhes.vue'),
    meta: {
      navActiveLink: 'vendas-tipos',
      pageTitle: 'Tipos de Vendas',
      resource: 'vendas-tipos',
      action: 'read',
      breadcrumb: [
        { text: 'Cadastros' },
        { text: 'Tipos de Vendas', to: { name: 'vendas-tipos' } },
        { text: 'Detalhes', active: true },
      ],
    },
  },
  {
    path: '/transportadoras',
    name: 'transportadoras',
    component: () => import('@/views/modules/transportadoras/TransportadorasListagem.vue'),
    meta: {
      pageTitle: 'Transportadoras',
      resource: 'transportadoras',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Transportadoras', active: true }],
    },
  },
  {
    path: '/transportadoras/detalhes',
    name: 'transportadoras-detalhes',
    component: () => import('@/views/modules/transportadoras/TransportadorasDetalhes.vue'),
    meta: {
      navActiveLink: 'transportadoras',
      pageTitle: 'Transportadoras',
      resource: 'transportadoras',
      action: 'read',
      breadcrumb: [
        { text: 'Cadastros' },
        { text: 'Transportadoras', to: { name: 'transportadoras' } },
        { text: 'Detalhes', active: true },
      ],
    },
  },

  // U
  {
    path: '/unidades',
    name: 'unidades',
    component: () => import('@/views/modules/unidades/UnidadesListagem.vue'),
    meta: {
      pageTitle: 'Unidades',
      resource: 'unidades',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Unidades', active: true }],
    },
  },
  {
    path: '/unidades/detalhes',
    name: 'unidades-detalhes',
    component: () => import('@/views/modules/unidades/UnidadesDetalhes.vue'),
    meta: {
      navActiveLink: 'unidades',
      pageTitle: 'Unidades',
      resource: 'unidades',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Unidades', to: { name: 'unidades' } }, { text: 'Detalhes', active: true }],
    },
  },

  // V
  {
    path: '/veiculos',
    name: 'veículos',
    component: () => import('@/views/modules/veículos/VeículosListagem.vue'),
    meta: {
      pageTitle: 'Veículos',
      resource: 'veículos',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Veículos', active: true }],
    },
  },
  {
    path: '/veiculos/detalhes',
    name: 'veículos-detalhes',
    component: () => import('@/views/modules/veículos/VeículosDetalhes.vue'),
    meta: {
      navActiveLink: 'veículos',
      pageTitle: 'Veículos',
      resource: 'veículos',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Veículos', to: { name: 'veículos' } }, { text: 'Detalhes', active: true }],
    },
  },
  {
    path: '/vendas',
    name: 'vendas',
    component: () => import('@/views/modules/vendas/VendasListagem.vue'),
    meta: {
      pageTitle: 'Vendas',
      resource: 'vendas',
      action: 'read',
      breadcrumb: [{ text: 'Cadastros' }, { text: 'Vendas', active: true }],
    },
  },
  {
    path: '/vendas/detalhes',
    name: 'vendas-detalhes',
    component: () => import('@/views/modules/vendas/VendasDetalhes.vue'),
    meta: {
      navActiveLink: 'vendas',
      pageTitle: 'Vendas',
      resource: 'vendas',
      action: 'read',
      breadcrumb: [{ text: 'Tabelas Gerais' }, { text: 'Vendas', to: { name: 'vendas' } }, { text: 'Detalhes', active: true }],
    },
  },
  {
    path: '/vendas/dashboard',
    name: 'vendas-dashboard',
    component: () => import('@/views/modules/vendas/VendasDashboard.vue'),
    meta: {
      navActiveLink: 'vendas-dashboard',
      pageTitle: 'Vendas',
      resource: 'vendas',
      action: 'dashboard',
      breadcrumb: [
        { text: 'Vendas', to: { name: 'vendas' } },
        { text: 'Dashboard', active: true },
      ],
    },
  },
  // {
  //   path: '/visitas',
  //   name: 'visitas',
  //   component: () => import('@/views/modules/visitas/VisitasListagem.vue'),
  //   meta: {
  //     pageTitle: 'Visitas',
  //     resource: 'visitas',
  //     action: 'read',
  //     breadcrumb: [
  //       { text: 'Cadastros' },
  //       { text: 'Visitas', active: true },
  //     ],
  //   },
  // },
  // {
  //   path: '/visitas/calendarios',
  //   name: 'visitas-calendários',
  //   component: () => import('@/views/modules/visitas/VisitasCalendário.vue'),
  //   meta: {
  //     navActiveLink: 'visitas-calendários',
  //     pageTitle: 'Visitas',
  //     resource: 'visitas',
  //     action: 'read',
  //     breadcrumb: [
  //       { text: 'Cadastros' },
  //       { text: 'Visitas', to: { name: 'visitas' } },
  //       { text: 'Calendário', active: true },
  //     ],
  //   },
  // },
  // {
  //   path: '/visitas/detalhes',
  //   name: 'visitas-detalhes',
  //   component: () => import('@/views/modules/visitas/VisitasDetalhes.vue'),
  //   meta: {
  //     navActiveLink: 'visitas',
  //     pageTitle: 'Visitas',
  //     resource: 'visitas',
  //     action: 'read',
  //     breadcrumb: [
  //       { text: 'Cadastros' },
  //       { text: 'Visitas', to: { name: 'visitas' } },
  //       { text: 'Detalhes', active: true },
  //     ],
  //   },
  // },
  // {
  //   path: '/visitas/itinerarios',
  //   name: 'visitas-itinerários',
  //   component: () => import('@/views/modules/visitas/VisitasItinerários.vue'),
  //   meta: {
  //     pageTitle: 'Itinerários de Visitas',
  //     resource: 'visitas',
  //     action: 'read',
  //     breadcrumb: [
  //       { text: 'Cadastros' },
  //       { text: 'Visitas', to: { name: 'visitas' } },
  //       { text: 'Itinerários', active: true },
  //     ],
  //   },
  // },
  // {
  //   path: '/visitas/itinerarios/detalhes',
  //   name: 'visitas-itinerários-detalhes',
  //   component: () => import('@/views/modules/visitas/VisitasItineráriosDetalhes.vue'),
  //   meta: {
  //     navActiveLink: 'visitas-itinerários',
  //     pageTitle: 'Itinerários de Visitas',
  //     resource: 'visitas',
  //     action: 'read',
  //     breadcrumb: [
  //       { text: 'Cadastros' },
  //       { text: 'Visitas', to: { name: 'visitas' } },
  //       { text: 'Itinerários', active: true },
  //     ],
  //   },
  // },
  // {
  //   path: '/visitas/processamento',
  //   name: 'visitas-processamento',
  //   component: () => import('@/views/modules/visitas/VisitasProcessamento.vue'),
  //   meta: {
  //     pageTitle: 'Visitas',
  //     resource: 'visitas',
  //     action: 'read',
  //     breadcrumb: [
  //       { text: 'Cadastros' },
  //       { text: 'Visitas', to: { name: 'visitas' } },
  //       { text: 'Processamento', active: true },
  //     ],
  //   },
  // },
]
