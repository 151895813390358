import Dexie from 'dexie'
import store from '@/store'
import axios from '@axios'
import axiosRaw from 'axios'

import { DateTime } from 'luxon'

export const storage = {
  database() {
    const db = new Dexie('ev')

    db.version(4).stores({
      meta: '&id',
      cache: '&id, time',
      clientes: '&id, nome, cpfcnpj, rginsc',
      produtos: '&id, nome, código_barras, referência, fk_empresas',
    })

    return db
  },

  /**
  * Função de dowload e validação de recursos offlines pelo indexedDB.
  * @param {Boolean} force se a função deve forçar um redownload
  * @param {String} table nome da tabela a ser armazenada
  * @param {String} url o endpoint da API
  * @param {JSON} params objeto JSON com os parâmetros de query da requisição GET
  * @since 1.0.0
  */
  async validate(force, table, url, params = {}) {
    const CACHE_EXPIRATION_MINUTES = 1440
    const db = storage.database()
    let isValid = false

    const metadata = await db.meta.where({ id: table }).first()

    // SE HÁ META REGISTRO VER PRIMEIRO SE ELE AINDA É VÁLIDO
    if (metadata) {
      const expiration = DateTime.utc().minus({ minutes: CACHE_EXPIRATION_MINUTES })
      const time = DateTime.fromSQL(metadata.time, { zone: 'utc' })

      // SE O CACHE AINDA É VÁLIDO
      if (time.valueOf() >= expiration.valueOf()) isValid = true
    }

    if (force || !isValid) {
      try {
        const res = await axios({ method: 'GET', url, params })

        await db.meta.put({ id: table, time: DateTime.utc().toSQL({ includeOffset: false }) })
        await db[table].bulkPut(res.data)

        isValid = true
      } catch (erro) {
        console.error(erro)
        isValid = false
      }
    }

    return isValid
  },

  async search(table, str, fieldsArr = ['nome']) {
    let arr = []
    const db = storage.database()

    for await (const field of fieldsArr) {
      const fields = await db[table].where(field).startsWithIgnoreCase(str).toArray()
      arr = [...arr, ...fields]
    }

    return arr
  },

  /**
   * Expurga os todos os dados do indexedDB armazenados no navegador.
   */
  async expurgar() {
    const db = storage.database()
    db.delete()
  },

  /**
   * Exclui um registro de uma dada tabela. Geralmente usado pra limpar um cache após edições naquela tabela.
  * @param {String} chave o nome da key a ser excluída
  * @param {String} tabela o nome da table, por padrão é 'cache'
   */
  async excluir(chave, tabela = 'cache') {
    const base = storage.database()
    const table = base.table(tabela)
    table.delete(chave)
  }
}

export const server = {
  async online() {
    let response

    try {
      await axios({
        method: 'GET',
        url: '/status/ping',
        timeout: 2500,
        params: {}
      })

      response = true
    } catch (error) {
      response = false
    }

    return response
  },

  async get(url, params = {}) {
    const response = {}

    try {
      const res = await axios({ method: 'GET', url, params })
      response.data = res.data
      response.error = false
      response.status = res.status
    } catch (error) {
      // console.error(error)
      response.error = true
      response.data = error.response.data
      response.status = error.response.status
    }

    return response
  },

  async post(url, body = {}) {
    const response = {}

    try {
      const res = await axios({ method: 'POST', url, data: body })
      response.data = res.data
      response.error = false
      response.status = res.status
    } catch (error) {
      console.error(error)
      response.error = true
      response.data = error.response.data
      response.status = error.response.status
    }

    return response
  },

  async put(url, body = {}) {
    const response = {}

    try {
      const res = await axios({ method: 'PUT', url, data: body })
      response.data = res.data
      response.error = false
      response.status = res.status
    } catch (error) {
      console.error(error)
      response.error = true
      response.data = error.response.data
      response.status = error.response.status
    }

    return response
  },

  async patch(url, body = {}) {
    const response = {}

    try {
      const res = await axios({ method: 'PATCH', url, data: body })
      response.data = res.data
      response.error = false
      response.status = res.status
    } catch (error) {
      console.error(error)
      response.error = true
      response.data = error.response.data
      response.status = error.response.status
    }

    return response
  },

  async delete(url, params = {}) {
    const response = {}

    try {
      const res = await axios({ method: 'DELETE', url, params })
      response.data = res.data
      response.error = false
      response.status = res.status
    } catch (error) {
      console.error(error)
      response.error = true
      response.data = error.response.data
      response.status = error.response.status
    }

    return response
  },

  async raw(method, url, params = {}) {
    const response = {}

    try {
      const res = await axios({ method, url, params })
      response.data = res.data
      response.error = false
      response.status = res.status
    } catch (error) {
      console.error(error)
      response.error = true
      response.data = error.response.data
      response.status = error.response.status
    }

    return response
  },

  async nãoInterceptado(method, url, params = {}) {
    const uninterceptedAxiosInstance = axiosRaw.create()
    const response = {}

    try {
      const res = await uninterceptedAxiosInstance({ method, url, params })
      response.data = res.data
      response.error = false
      response.status = res.status
    } catch (error) {
      console.error(error)
      response.error = true
      response.data = error.response.data
      response.status = error.response.status
    }

    return response
  },

  async cache(force, table, url, params = {}) {
    const CACHE_EXPIRATION_MINUTES = 15
    const db = storage.database()

    let emCache
    const collection = await db.cache.where({ id: table }).first()

    if (collection) {
      const expiration = DateTime.utc().minus({ minutes: CACHE_EXPIRATION_MINUTES })
      const time = DateTime.fromSQL(collection.time, { zone: 'utc' })
      emCache = time.valueOf() >= expiration.valueOf() ? collection.cache : undefined
    }

    if (force || emCache === undefined) {
      try {
        console.info(`%cCache invalidado ou inexistente. Recarregando a tabela ${table}`, 'color: #F44336')

        const res = await axios({ method: 'GET', url, params })
        await db.cache.put({
          id: table,
          cache: res.data,
          time: DateTime.utc().toSQL({ includeOffset: false }),
        })

        return res.data
      } catch (erro) {
        console.error(erro)
      }
    } else {
      console.info(`%cCache em disco ainda válido para a tabela ${table} e carregado com sucesso.`, 'color: #4CAF50')
      return emCache
    }
  },

  async arquivo(url, data, params = {}) {
    const response = {}
    const headers = { 'Content-Type': 'multipart/form-data' }

    try {
      const res = await axios({ method: 'POST', url, params, headers, data })
      response.data = res.data
      response.error = false
      response.status = res.status
    } catch (error) {
      console.error(error)
      response.error = true
      response.data = error.response.data
      response.status = error.response.status
    }

    return response
  },
}

export const sync = {
  rede(event) {
    if (navigator.onLine) console.info('%cInternet disponível novamente.', 'color: #4CAF50')
    else console.info('%cPerda de conexão com a internet.', 'color: #4CAF50')
  },

  async offline() {
    console.time('Sincronização offline')

    try {
      await Promise.all([
        storage.validate(true, 'clientes', '/clientes/cache'),
        storage.validate(true, 'produtos', '/produtos/cache'),
      ])
    } catch (error) {
      console.error(error)
    }

    console.timeEnd('Sincronização offline')
  },

  async contatos() {
    const res = await server.get('/contatos/todos')
    if (!res.error) {
      await store.dispatch('data/contatos', res.data)
    }
  },
}

export const share = {
  phone(telefone, modo = 'L') {
    if (!telefone || telefone.length < 8) return

    let tel = telefone

    // LIGAÇÃO
    if (modo == 'L') document.location.href = `tel:${tel}`

    // WHATSAPP
    if (modo == 'W') {
      if (tel.length > 9 && tel.length < 12) tel = `55${tel}`

      const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      if (mobile) document.location.href = `whatsapp://send?phone=${tel}`
      else window.open(`https://web.whatsapp.com/send?phone=${tel}&amp;app_absent`, 'whatsapp')
    }
  },

  email(email) {
    if (email) document.location.href = `mailto:${email}`
  },

  website(url) {
    if (!url) return
    let urlProtocolada = url
    if (urlProtocolada.slice(0, 4) != 'http') urlProtocolada = `http://${urlProtocolada}`
    window.open(urlProtocolada, '_blank')
  },
}

export const format = {
  cpfcnpj(n) {
    if (!n) return ''

    switch (n.length) {
      case 11: return [n.slice(0, 3), '.', n.slice(3, 6), '.', n.slice(6, 9), '-', n.slice(9)].join('')
      case 14: return [n.slice(0, 2), '.', n.slice(2, 5), '.', n.slice(5, 8), '/', n.slice(8, 12), '-', n.slice(12)].join('')
      case 15: return [n.slice(0, 3), '.', n.slice(3, 6), '.', n.slice(6, 9), '/', n.slice(9, 13), '-', n.slice(13)].join('')
      default: return n
    }
  },

  telefone(telefone) {
    if (!telefone) return ''
    let tel = telefone

    tel = tel.replace(/[^\d]+/g, '')

    switch (tel.length) {
      case 14: return ['+', tel.slice(0, 3), ' ', tel.slice(3, 5), ' ', tel.slice(5, 10), '-', tel.slice(10)].join('')
      case 13: return ['+', tel.slice(0, 2), ' ', tel.slice(2, 4), ' ', tel.slice(4, 9), '-', tel.slice(9)].join('')
      case 12: return ['+', tel.slice(0, 2), ' ', tel.slice(2, 4), ' ', tel.slice(4, 8), '-', tel.slice(8)].join('')
      case 11: return [tel.slice(0, 2), ' ', tel.slice(2, 7), '-', tel.slice(7)].join('')
      case 10: return [tel.slice(0, 2), ' ', tel.slice(2, 6), '-', tel.slice(6)].join('')
      case 8: return [tel.slice(0, 4), '-', tel.slice(4)].join('')
      default: return tel
    }
  },

  cep(n) {
    if (!n) return ''

    if (n.length === 8) return [n.slice(0, 5), '-', n.slice(5)].join('')
    return n
  },

  porcentagem(val, multiplicar = true) {
    const valor = multiplicar ? (Number(val) * 100) : Number(val)
    return `${valor.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: false,
    })}%`
  },

  centavos(amount) {
    if (!amount || Number.isNaN(Number(amount))) return 0
    if (typeof amount !== 'string' && typeof amount !== 'number') return 0
    return Math.round(100 * parseFloat(amount))
  },

  número(valor) {
    return Number(valor).toLocaleString('pt-BR')
  },

  moeda(valor, códigoMoeda = 'BRL', símbolo = true) {
    const formatador = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: códigoMoeda })

    if (símbolo) return formatador.format(valor)

    const opções = {
      minimumFractionDigits: formatador.resolvedOptions().maximumFractionDigits,
      maximumFractionDigits: formatador.resolvedOptions().maximumFractionDigits
    }
    return Number(valor).toLocaleString('pt-BR', opções)
  },

  quantidade(valor) {
    const casas = store.getters['data/filial'].casasDecimais

    const opções = {
      minimumFractionDigits: casas,
      maximumFractionDigits: casas,
      useGrouping: true
    }

    return Number(valor).toLocaleString('pt-br', opções)
  },

  quantidadeForce(valor, casas) {
    const opções = {
      minimumFractionDigits: casas,
      maximumFractionDigits: casas,
      useGrouping: true
    }

    return Number(valor).toLocaleString('pt-br', opções)
  },

  /**
   * Formata um array de endereços para exibição em selects.
   * @param {Array} arrEnd lista de endereços do servidor
   */
  listaEndereços(arrEnd) {
    arrEnd.forEach((e, i, arr) => {
      e.nomeExibição = ''

      if (e.principal == 'S' || e.entrega == 'S' || e.cobrança == 'S') {
        e.nomeExibição += '('
        if (e.principal == 'S') e.nomeExibição += 'P, '
        if (e.entrega == 'S') e.nomeExibição += 'E, '
        if (e.cobrança == 'S') e.nomeExibição += 'C, '
        e.nomeExibição = e.nomeExibição.slice(0, -2)
        e.nomeExibição += ') '
      }

      // NOME DE EXIBIÇÃO QUE APARECE NO SELECT
      if (e.nome) e.nomeExibição += e.nome
      else {
        const nome = [e.logradouro, e.número || 'S/N', e.bairro, e.cidade, e.uf, e.país]
        e.nomeExibição += nome.filter(Boolean).join(', ').slice(0, 100)
      }

      e.nomeExibição = String(e.nomeExibição).toUpperCase()
    })

    return arrEnd
  }
}

// https://stackoverflow.com/a/56391657
export function scroll(el) {
  const yOffset = -100
  const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset
  window.scrollTo({ top: y, behavior: 'smooth' })
}

export const tempo = {
  data: {

    /**
     * Retorna a data local atual no formato ISO8601
     * @param {Object} offsetDias um objeto no modelo Luxon: { days: n }
     * @returns uma string com a data ISO atual do sistema
     */
    agora(offsetDias = null) {
      let data = DateTime.local()
      if (offsetDias) data = data.plus(offsetDias)
      return data.toISO({ includeOffset: false })
    },

    /**
     * Transforma uma data em horário no fuso local.
     * @param {String} dateString a string UTC no formato 'yyyy-MM-dd'
     * @param {String} entrada o tipo da entrada, pode ser 'SQL' ou 'ISO'
     */
    local(string, entrada = 'ISO') {
      if (!string) return ''
      if (entrada == 'ISO') return DateTime.fromISO(string, { zone: 'utc' }).toLocaleString()
      if (entrada == 'SQL') return DateTime.fromSQL(string, { zone: 'utc' }).toLocaleString()
    },

    /**
     * Retorna o dia da semana localizado.
     * @param {String} string a string de data no formato ISO.
     * @returns boolean
     */
    semana(string) {
      const fuso = store.getters['data/usuário'].fuso || 'America/Sao_Paulo'
      return DateTime.fromISO(string, { zone: 'utc' }).setZone(fuso).setLocale('pt-BR').toFormat('cccc')
    },

    /**
     * Retorna se a string de data passada é do dia do sistema.
     * @param {String} string a string de data no formato ISO.
     * @returns boolean
     */
    hoje(string) {
      return DateTime.fromISO(string, { zone: 'utc' }).toISODate() === DateTime.local().toISODate()
    },
  },

  datahora: {
    /**
     * Retorna a datahora UTC atual no formato ISO8601
     * @param {Object} offsetDias um objeto no modelo Luxon: { days: n }
     * @returns uma string com a data ISO atual do sistema
     */
    agora(offsetDias = null) {
      let data = DateTime.utc()
      if (offsetDias) data = data.plus(offsetDias)
      return data.toISO({ includeOffset: false })
    },

    /**
     * Transforma uma datahora em horário no fuso local.
     * @param {String} string a string no formato SQL 'YYYY-MM-DD HH:mm:ss'
     * @param {String} entrada o tipo da entrada, pode ser 'SQL' ou 'ISO'
     */
    local(string, entrada = 'ISO') {
      if (!string) return ''

      const fuso = store.getters['data/usuário'].fuso || 'America/Sao_Paulo'

      if (entrada == 'ISO') {
        return DateTime.fromISO(string, { zone: 'utc' })
          .setZone(fuso)
          .toFormat('dd/MM/yyyy HH:mm:ss')
        // .toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
      }

      if (entrada == 'SQL') {
        return DateTime.fromSQL(string, { zone: 'utc' })
          .setZone(fuso)
          .toFormat('dd/MM/yyyy HH:mm:ss')
        // .toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
      }
    },

    /**
     * Converte as datahoras do padrão ISO8601 para o padrão SQL.
     * @param {String} dataISO string de datahora
     * @returns string no padrão SQL 'YYYY-MM-DD HH:mm:ss'
     */
    normalizar(dataISO) {
      if (!dataISO) return ''
      return DateTime.fromISO(dataISO, { zone: 'utc' }).toSQL({ includeOffset: false })
    },

    /**
     * Retorna a diferença entre a datahora passada e a hora atual.
     * @param {String} dateString string de datahora
     * @param {Array} arrUnits array de unidades em inglês para retornar
     * @returns objeto com as unidades passadas
     */
    diferença(dateString, arrUnits = ['years', 'months', 'days', 'hours']) {
      if (!dateString) return
      const date = DateTime.fromISO(dateString, { zone: 'utc' })
      const dateNow = DateTime.utc()
      return dateNow.diff(date, arrUnits)
    },

    /**
     * Retorna a diferença entre a datahora passada e a hora atual.
     */
    relativo(dateString) {
      if (!dateString) return ''
      const date = DateTime.fromISO(dateString, { zone: 'utc' })
      return date.toRelative()
    },
  }
}

export const time = {
  /**
   * Retorna um objeto Luxon com a data UTC atual.
   */
  raw() {
    return DateTime.utc()
  },

  date: {
    /**
     * Retorna uma string de data no formato local.
     * @param {String} dateString a string UTC no formato 'yyyy-MM-dd'
     * @param {String} locale o locale de saída, por padrão 'pt-BR'
     */
    toLocal(dateString, locale = 'pt-BR') {
      if (!dateString) return
      return DateTime.fromSQL(dateString, { zone: 'utc' }).toLocaleString({ locale })
    },

    /**
     * Retorna uma string de data no formato suportado pelo servidor.
     * @param {String} dateString a string UTC no formato 'dd/MM/yyyy'
     * @param {String} locale o locale de saída, por padrão 'pt-BR'
     */
    toUTC(dateString, locale = 'pt-BR') {
      if (!dateString) return null
      return DateTime.fromFormat(dateString, 'ddMMyyyy', { locale }).toFormat('yyyy-MM-dd')
    },

    diffToNow(dateString, arrUnits = ['years', 'months', 'days', 'hours'], locale = 'pt-BR') {
      if (!dateString) return
      const date = DateTime.fromISO(dateString, { zone: 'utc' })
      const dateNow = DateTime.utc()
      return dateNow.diff(date, arrUnits)
    }
  },

  datetime: {
    agora() {
      return DateTime.utc().toLocal().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
    },

    novo(local = false, offset = null) {
      let datahora = DateTime.utc()
      if (local) datahora = datahora.toLocal()
      if (offset) datahora = datahora.plus(offset)

      return datahora.toSQL({ includeOffset: false })
    },

    /**
     * Retorna uma string de datahora no formato local.
     * @param {String} dateString a string UTC no formato 'yyyy-MM-dd HH:mm:ss'
     * @param {String} locale o locale de saída, por padrão 'pt-BR'
     */
    toLocal(dateString) {
      if (!dateString) return ''
      return DateTime.fromISO(dateString, { zone: 'utc' }).toLocal().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
    },

    /**
     * Retorna uma string de data no formato suportado pelo servidor.
     * @param {String} dateString a string UTC no formato 'dd/MM/yyyy'
     * @param {String} locale o locale de saída, por padrão 'pt-BR'
     */
    toUTC(dateString, locale = 'pt-BR') {
      if (!dateString) return null
      return DateTime.fromFormat(dateString, 'dd/MM/yyyy HH:mm:ss', { locale }).toSQL({ includeOffset: false })
    },

    /**
     * Retorna uma string de data no formato suportado pelo servidor.
     * @param {String} dateString a string UTC no formato 'dd/MM/yyyy'
     * @param {String} locale o locale de saída, por padrão 'pt-BR'
     */
    toRelative(dateString, locale = 'pt-BR') {
      if (!dateString) return ''
      return DateTime.fromISO(dateString, { zone: 'utc' }).toRelative()
    },

    /**
     * Transforma uma string de datahora para o formato SQL.
     * @param {String} dateString a string UTC
     * @param {String} dateString o formato, por padrão 'YYYY-MM-DD HH:mm:ss'
     * @param {String} locale o locale de saída, por padrão 'pt-BR'
     */
    padronizar(dateString, formato = 'YYYY-MM-DD HH:mm:ss', locale = 'pt-BR') {
      if (!dateString) return null
      return DateTime.fromFormat(dateString, formato, { locale }).toSQL({ includeOffset: false })
    },
  },
}

export const validação = {
  uuid: function uuid(str) {
    return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(str)
  },

  cpf(str) {
    const cpf = str.replace(/[^\d]+/g, '')
    if (!cpf) return false

    if (cpf == '00000000000' || cpf == '11111111111'
      || cpf == '22222222222' || cpf == '33333333333'
      || cpf == '44444444444' || cpf == '55555555555'
      || cpf == '66666666666' || cpf == '77777777777'
      || cpf == '88888888888' || cpf == '99999999999') return false

    let sum = 0
    let remainder

    for (let i = 1; i <= 9; i += 1) sum += parseInt(cpf.substring(i - 1, i), 10) * (11 - i)
    remainder = (sum * 10) % 11

    if ((remainder == 10) || (remainder == 11)) remainder = 0
    if (remainder != parseInt(cpf.substring(9, 10), 10)) return false

    sum = 0
    for (let i = 1; i <= 10; i += 1) sum += parseInt(cpf.substring(i - 1, i), 10) * (12 - i)
    remainder = (sum * 10) % 11

    if ((remainder == 10) || (remainder == 11)) remainder = 0
    if (remainder != parseInt(cpf.substring(10, 11), 10)) return false
    return true
  },

  cnpj(str) {
    const cpf = str.replace(/[^\d]+/g, '')

    if (!cpf) return false
    if (cpf.length != 14) return false
    if (cpf == '00000000000000' || cpf == '11111111111111'
      || cpf == '22222222222222' || cpf == '33333333333333'
      || cpf == '44444444444444' || cpf == '55555555555555'
      || cpf == '66666666666666' || cpf == '77777777777777'
      || cpf == '88888888888888' || cpf == '99999999999999') return false

    let tamanho = cpf.length - 2
    let numeros = cpf.substring(0, tamanho)
    const digitos = cpf.substring(tamanho)
    let soma = 0
    let pos = tamanho - 7
    let resultado

    for (let i = tamanho; i >= 1; i -= 1) {
      soma += numeros.charAt(tamanho - i) * pos
      pos -= 1
      if (pos < 2) pos = 9
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
    if (resultado != digitos.charAt(0)) return false

    tamanho += 1
    numeros = cpf.substring(0, tamanho)
    soma = 0
    pos = tamanho - 7

    for (let i = tamanho; i >= 1; i -= 1) {
      soma += numeros.charAt(tamanho - i) * pos
      pos -= 1
      if (pos < 2) pos = 9
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
    if (resultado != digitos.charAt(1)) return false
    return true
  },

  cpfcnpj(str) {
    const cpfcnpj = str.replace(/[^\d]+/g, '')
    if (cpfcnpj.length == 11) return validação.cpf(cpfcnpj)
    if (cpfcnpj.length == 14) return validação.cnpj(cpfcnpj)
    return false
  },

  data(str) {
    const data = DateTime.fromFormat(str, 'ddMMyyyy', { locale: 'pt-BR' })
    return data.isValid
  },

  nascimento(str) {
    const data = DateTime.fromFormat(str, 'ddMMyyyy', { locale: 'pt-BR' })
    return data.isValid
  },

  datahora(str) {
    const data = DateTime.fromSQL(str, { locale: 'pt-BR' })
    return data.isValid
  },
}
